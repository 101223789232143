<template>
    <div class="mt-5 px-xl-5">
        <div class="mb-5">
            <b-overlay :show="loading" rounded="sm">
                <b-card no-body class="p-4">
                    <div>
                        <h5 class="card-title">REPORTE DE INFORMACIÓN VARIABLE</h5>
                        <b-row class="mt-2">
                            <b-col class="mt-2">
                                <b-form-group>
                                    <select class="form-select form-select-sm rounded-0" v-model="report" required
                                        @change="changeReport">
                                        <option :value="null"></option>
                                        <option v-for="(row, index) in reports" :key="index" :value="row.key">
                                            {{ row.value }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-4">
                        <h6 class="card-title">FILTROS:</h6>
                        <b-row>
                            <b-col class="mt-2" md="4">
                                <b-form-group label="Desde">
                                    <DatePicker v-model="filters.startDate" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4">
                                <b-form-group label="Hasta">
                                    <DatePicker v-model="filters.endDate" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 0 || report === 7 || report === 11">
                                <b-form-group label="Tipo de agente residente">
                                    <select class="form-select form-select-sm rounded-0"
                                        v-model="filters.residentAgentType">
                                        <option :value="null">Todos</option>
                                        <option value="1">Persona natural</option>
                                        <option value="0">Persona jurídica</option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 9">
                                <b-form-group label="Tipo de Beneficiario Final">
                                    <v-select v-model="bfSelected" :options="finalBeneficiaryTypes"
                                        :reduce="(bf) => bf.value" :clearable="true">
                                        <template #selected-option="option">
                                            {{ option.text }}
                                        </template>
                                        <template #option="option">
                                            {{ option.text }}
                                        </template>
                                    </v-select>
                                </b-form-group>

                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 0 || report === 7 || report === 11">
                                <b-form-group label="CUR">
                                    <b-form-input v-model="filters.cur" size="sm" required class="rounded-0" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4"
                                v-if="report === 1 || report === 2 || report === 3 || report === 4 || report === 5 || report === 6 || report === 11 || report === 12">
                                <b-form-group label="Tipo de sociedad">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.societyType">
                                        <option :value="null"></option>
                                        <option value="sociedad anonima">
                                            {{ $t("society-types.sociedad anonima") }}
                                        </option>
                                        <option value="fundacion de interes propio">
                                            {{ $t("society-types.fundacion de interes propio") }}
                                        </option>
                                        <option value="sociedad limitada">
                                            {{ $t("society-types.sociedad limitada") }}
                                        </option>
                                        <option value="sociedad extranjera">
                                            {{ $t("society-types.sociedad extranjera") }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 11 || report === 12">
                                <b-form-group label="Clasificación Persona Jurídica">
                                    <select class="form-select form-select-sm rounded-0"
                                        v-model="filters.clasification">
                                        <option :value="null"></option>
                                        <option value="0">
                                            Persona Jurídica con operaciones y/o activos fuera de Panamá
                                        </option>
                                        <option value="1">
                                            Persona Jurídica con operaciones y/o activos en Panamá
                                        </option>
                                        <option value="2">
                                            Persona jurídica listada en Bolsa de Valores
                                        </option>
                                        <option value="3">
                                            Persona jurídica propiedad de una Entidad Estatal o Multilateral
                                        </option>
                                        <option value="4">
                                            Persona jurídica propiedad de un Estado
                                        </option>
                                        <option value="5">
                                            Persona jurídica con operaciones y/o activos en varias jurisdicciones
                                            incluyendo Panamá
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4"
                                v-if="report === 1 || report === 2 || report === 3 || report === 4 || report === 5 || report === 6 || report === 8 || report === 11 || report === 12">
                                <b-form-group label="Nombre de la Persona Jurídica">
                                    <b-form-input v-model="filters.name" size="sm" required class="rounded-0" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4"
                                v-if="report === 1 || report === 2 || report === 3 || report === 4 || report === 5 || report === 6 || report === 8 || report === 11 || report === 12">
                                <b-form-group label="Folio">
                                    <b-form-input v-model="filters.folio" size="sm" required class="rounded-0" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 3">
                                <b-form-group label="País">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.country">
                                        <option :value="null"></option>
                                        <option v-for="country in countries" :key="country" :value="country">
                                            {{ country }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4"
                                v-if="report === 1 || report === 3 || report === 4 || report === 6 || (report === 9 && showJurisdictionSelect)">
                                <b-form-group label="Jurisdicción donde opera la bolsa de valores">
                                    <select class="form-select form-select-sm rounded-0"
                                        v-model="filters.countryJurisdiction">
                                        <option :value="null"></option>
                                        <option v-for="country in countriesBolsa" :key="country" :value="country">
                                            {{ country }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 9">
                                <b-form-group :label="countryLabelName">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.country">
                                        <option :value="null"></option>
                                        <option v-for="country in countries" :key="country" :value="country">
                                            {{ country }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 3 || (report === 9 && showExchangeFullName)">
                                <b-form-group label="Nombre en bolsa de valores">
                                    <b-form-input v-model="filters.stockExchangeName" size="sm" required
                                        class="rounded-0" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4"
                                v-if="report === 0 || report === 1 || report === 2 || report === 3 || report === 4 || report === 5 || report === 6 || report === 7 || report === 9 || report === 11">
                                <b-form-group label="Firmas de abogados">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.lawFirmId"
                                        :disabled="alertDynamicId" @change="onChangeRA">
                                        <option :value="null"></option>
                                        <option v-for="(item, i) in lawFirms" :key="i" :value="item.id">
                                            {{ item.name.toUpperCase() }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 11">
                                <b-form-group label="Usuarios">
                                    <v-select v-model="filters.userId" label="name" :options="users"
                                        :reduce="(lf) => lf.userId" :clearable="true">
                                        <template #selected-option="option">
                                            {{ option.username }}
                                        </template>
                                        <template #option="option">
                                            {{ option.username }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 0 || report === 7">
                                <b-form-group label="CUR Activo">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.curStatus">
                                        <option :value="null"></option>
                                        <option value="Si">Si</option>
                                        <option value="No">No</option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4"
                                v-if="report === 1 || report === 2 || report === 3 || report === 4 || report === 5 || report === 6 || report === 12">
                                <b-form-group label="Entidad Legal Activa">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.leStatus">
                                        <option :value="null"></option>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 7">
                                <b-form-group label="Rol Habilitado">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.arEnabled">
                                        <option :value="null"></option>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 7">
                                <b-form-group label="Invitacion usada">
                                    <select class="form-select form-select-sm rounded-0"
                                        v-model="filters.usedInvitation">
                                        <option :value="null"></option>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 8">
                                <b-form-group label="Código">
                                    <b-form-input v-model="filters.code" size="sm" required class="rounded-0" />
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 8">
                                <b-form-group label="Autoridad competente">
                                    <v-select v-model="filters.acId" label="name" :options="competentAuthoritys"
                                        :reduce="(lf) => lf.id" :clearable="true">
                                        <template #selected-option="option">
                                            {{ option.name }}
                                        </template>
                                        <template #option="option">
                                            {{ option.name }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 8">
                                <b-form-group label="Estado">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.fbStatus">
                                        <option :value="null"></option>
                                        <option value="0">Pendiente primera validación</option>
                                        <option value="1">Pendiente segunda validación</option>
                                        <option value="2">Pendiente de lectura</option>
                                        <option value="3">En lectura</option>
                                        <option value="4">Cerrado</option>
                                        <option value="5">Cancelado</option>
                                    </select>
                                </b-form-group>
                            </b-col>
                            <b-col class="mt-2" md="4" v-if="report === 9">
                                <b-form-group label="Motivos">
                                    <select class="form-select form-select-sm rounded-0" v-model="filters.reason">
                                        <option :value="null"></option>
                                        <option v-for="reason in reasons" :key="reason.key" :value="reason.key">
                                            {{ reason.label }}
                                        </option>
                                    </select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="mt-2">
                                <b-button variant="secondary" @click="view">
                                    Ver
                                    <b-icon-eye />
                                </b-button>
                                <!-- <b-button variant="secondary" @click="downloadCsv" class="ms-2">
                                    Descargar CSV
                                    <b-icon-download />
                                </b-button> -->
                                <b-button variant="secondary" @click="downloadExcel" class="ms-2">
                                    Descargar EXCEL
                                    <b-icon-download />
                                </b-button>
                                <!-- <b-button variant="secondary" @click="downloadPdf" class="ms-2">
                                    Descargar en PDF
                                    <b-icon-download />
                                </b-button> -->
                                <b-button variant="secondary" @click="changeReport" class="ms-2">
                                    Reiniciar
                                    <b-icon-funnel />
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="viewTable" class="mt-4">
                        <b-table ref="reportTable" :items="retrieveData" :current-page="currentPage" :per-page="take"
                            responsive striped hover>
                        </b-table>

                        <b-row class="mt-4 mb-5">
                            <b-col class="flex" md="6">
                                <label class="label-title">Paginas a exportar</label>
                                <b-form-input type="number" class="rounded-0" size="sm" v-model="pageTake"
                                    style="width: 60px" @input="handleInputChange" />
                            </b-col>
                            <b-col md="6">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                                    class="my-0 justify-content-end">
                                    <template #first-text>
                                        <span><b-icon-chevron-double-left
                                                scale="1.3"></b-icon-chevron-double-left></span>
                                    </template>
                                    <template #prev-text>
                                        <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                                    </template>
                                    <template #next-text>
                                        <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                                    </template>
                                    <template #last-text>
                                        <span><b-icon-chevron-double-right
                                                scale="1.3"></b-icon-chevron-double-right></span>
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>

                    </div>
                </b-card>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import DatePicker from "@/components/widgets/DatePicker.vue";
import { mapActions, mapGetters } from "vuex";
import { countries } from "@/config/countries";
import { reasons } from "@/config/final-beneficiary-reasons";
import { countriesBolsa } from "@/config/countriesBolsa";

export default {
    name: "variable-information-reports",
    components: {
        DatePicker
    },
    data() {
        return {
            countries: countries,
            countriesBolsa: countriesBolsa,
            loading: false,
            report: null,
            currentPage: 1,
            take: 10,
            rows: 0,
            reports: [
                // { key: 0, value: "Reporte de Sociedades por Agente Residente" },
                { key: 1, value: "Persona Jurídica con operaciones y/o activos fuera de Panamá" },
                { key: 2, value: "Persona Jurídica con operaciones y/o activos en Panamá" },
                { key: 3, value: "Persona Jurídica listada en Bolsa de Valores" },
                { key: 4, value: "Persona Jurídica propiedad de una Entidad Estatal o Multilateral" },
                { key: 5, value: "Persona Jurídica propiedad de un estado" },
                { key: 6, value: "Persona Jurídica con operaciones y/o activos en varias jurisdicciones incluyendo Panamá" },
                { key: 7, value: "Reporte de Invitaciones" },
                { key: 8, value: "Procesos de Beneficiario Final" },
                { key: 9, value: "Información Variable Beneficiario Final" },
                { key: 10, value: "Abogados/Firmas" },
                { key: 11, value: "Reporte de las sociedades por Agente Residente" },
                { key: 12, value: "Reporte Personas Jurídicas" }
            ],
            finalBeneficiaryTypes: [
                { value: 0, text: 'Persona Natural' },
                { value: 1, text: 'Persona Jurídica que cotiza en bolsa' },
                { value: 2, text: 'Estado' },
                { value: 3, text: 'Entidad Estatal o Multilateral' }
            ],
            items: [],
            reasons: [],
            filters: {},
            lawFirms: [],
            users: [],
            competentAuthoritys: [],
            countryLabelName: 'Nacionalidad',
            bfSelected: null,
            residentAgentType: '',
            isBusy: false,
            viewTable: false,
            pageTake: 0
        };
    },
    mounted() {
        this.reasons = reasons;
    },
    watch: {
        bfSelected: function () {
            switch (this.bfSelected) {
                case 1:
                    this.countryLabelName = 'Pais de constitución';
                    break
                case 2:
                    this.countryLabelName = 'Nombre del País';
                    break
                case 3:
                    this.countryLabelName = 'País o Sede';
                    break
                default:
                    this.countryLabelName = 'Nacionalidad';

            }
            this.filters.Who = this.bfSelected
        },

    },
    computed: {
        ...mapGetters("oidcStore", ["oidcAccessToken"]),
        showJurisdictionSelect: function () {
            return this.bfSelected === 1
        },
        showExchangeFullName: function () {
            return this.bfSelected === 1
        }
    },
    created() {
        this.loadLawFirms();
        this.loadCompetentAuthoritys();
        this.loadUsers("")
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async loadLawFirms() {
            try {
                var response = await RepositoryFactory.lawFirm.getAll({ search: "", status: "" });
                this.lawFirms = response.data.filter((n) => n.name !== null);
            } catch (error) {
                window.console.log(error);
            }
        },
        async loadUsers(type, lawFirm) {
            try {
                var response = await RepositoryFactory.residentAgent.getUsers(type, lawFirm);
                this.users = response.data;
            } catch (error) {
                window.console.log(error);
            }
        },
        async onChangeRA() {
            await this.loadUsers(this.filters.residentAgentType, this.filters.lawFirmId)
        },
        async loadCompetentAuthoritys() {
            try {
                var response = await RepositoryFactory.competentAuthority.getAll({ search: "" });
                this.competentAuthoritys = response.data;
            } catch (error) {
                window.console.log(error);
            }
        },
        async changeReport() {
            this.filters = {};
            this.items = [];
            this.bfSelected = null
        },
        async view() {
            this.currentPage = 1
            this.viewTable = true;

            let table = this.$refs.reportTable;

            if (table !== undefined) {
                table.refresh();
            }
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let items = [];
                this.filters.contentType = 'text/csv';
                let payload = {};
                payload.filters = this.filters;
                payload.page = context.currentPage === undefined ? 1 : context.currentPage;
                payload.take = 10;
                var file = await this.getData(payload);
                this.rows = file.data.total;
                this.pageTake = Math.ceil(file.data.total / this.take);

                var response = await RepositoryFactory.variableInformationReport.getFile({
                    content: file.data.fileContent,
                    fileName: file.data.fileName,
                    contentType: file.data.contentType
                })

                if (response != null) {
                    const rows = response.data.split('\n').map(fila => fila.trim());
                    if (rows[rows.length - 1] === '') {
                        rows.pop();
                    }
                    const headboard = rows.shift().split(';');
                    items = rows.map(r => {
                        const values = r.split(';');
                        const row = {};
                        headboard.forEach((column, index) => {
                            row[column] = values[index];
                        });
                        return row;
                    });
                }
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        async downloadCsv() {
            this.filters.contentType = 'text/csv';
            await this.responseData();
            RepositoryFactory.event.add({
                code: 2004,
                details: "csv file download"
            });
        },
        async downloadExcel() {
            this.filters.contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            await this.responseData();
            RepositoryFactory.event.add({
                code: 2004,
                details: "excel file download"
            });
        },
        async downloadPdf() {
            this.filters.contentType = 'application/pdf';
            await this.responseData();
            RepositoryFactory.event.add({
                code: 2004,
                details: "pdf file download"
            });
        },
        async responseData() {
            let payload = {
                filters: this.filters,
                page: 1,
                take: this.pageTake * this.take
            }
            var response = await this.getData(payload);
            if (response != null) {
                let report = this.reports.find(x => x.key === this.report);
                await this.downloadFile(
                    response.data,
                    report.value,
                    this.filters.contentType
                );
            }
        },
        async getData(payload) {
            try {
                this.loading = true;
                const startDate = new Date(this.filters.startDate);
                const endDate = new Date(this.filters.endDate);

                if (isNaN(startDate.getTime())) {
                    this.filters.startDate = "";
                }

                if (isNaN(endDate.getTime())) {
                    this.filters.endDate = "";
                }

                if (this.filters.startDate !== "" && this.filters.endDate !== "") {
                    if (endDate.getTime() < startDate.getTime()) {
                        this.openModal();
                        this.setModalInfo({
                            title: "Reporte de Información Variable",
                            message: "La fecha final debe ser mayor o igual a la fecha inicial",
                        });
                        return;
                    }
                }

                return await this.fetchRequest(payload);
            }
            catch (error) {
                let message = "Error al consumir servicio.";
                console.log(error)

                if (error?.response?.status === 400 && error?.response?.data) {
                    message = error.response.data
                }

                this.openModal();
                this.setModalInfo({
                    title: "Reporte de Información Variable",
                    message: message,
                });
            }
            finally {
                this.loading = false;
            }
        },
        async fetchRequest(payload) {
            let response = null;
            switch (this.report) {
                case 0:
                    response = await RepositoryFactory.variableInformationReport.getCompaniesByResidentAgent(payload);
                    break;
                case 1:
                    response = await RepositoryFactory.variableInformationReport.getLegalEntitiesNotDoneOperationPA(payload);
                    break;
                case 2:
                    response = await RepositoryFactory.variableInformationReport.getLegalEntitiesDoneOperationPA(payload);
                    break;
                case 3:
                    response = await RepositoryFactory.variableInformationReport.getLegalEntitiesListedStockExchanges(payload);
                    break;
                case 4:
                    response = await RepositoryFactory.variableInformationReport.getMultilateralStateEntity(payload);
                    break;
                case 5:
                    response = await RepositoryFactory.variableInformationReport.getLegalEntityOwnedByState(payload);
                    break;
                case 6:
                    response = await RepositoryFactory.variableInformationReport.getLegalEntityOperatingJurisdictionsIncludingPA(payload);
                    break;
                case 7:
                    response = await RepositoryFactory.variableInformationReport.getReportInvitations(payload);
                    break;
                case 8:
                    response = await RepositoryFactory.variableInformationReport.getFinalBeneficiaryProcesses(payload);
                    break;
                case 9:
                    response = await RepositoryFactory.variableInformationReport.getFinalBeneficiaryReport(payload);
                    break;
                case 10:
                    response = await RepositoryFactory.variableInformationReport.getLawFirmsReport(payload);
                    break;
                case 11:
                    response = await RepositoryFactory.variableInformationReport.getSocietiesByResidentAgents(payload);
                    break;
                case 12:
                    response = await RepositoryFactory.variableInformationReport.getLegalEntities(payload);
                    break;
                default:
                    break;
            }
            return response;
        },
        async downloadFile(data, filename, contenType) {
            const blob = new Blob([data], { type: contenType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);
        },
        handleInputChange(value) {
            this.pageTake = value;
        },
    },
};
</script>