export const countriesBolsa = [
    "ÁFRICA CENTRAL",
    "ALBANIA",
    "ALEMANIA",
    "ALBERTA",
    "ANDORRA",
    "ANGOLA",
    "ARABIA SAUDITA",
    "ARGELIA",
    "ARGENTINA",
    "ARMENIA",
    "AUSTRALIA",
    "AUSTRIA",
    "AZERBAIYÁN",
    "BAHAMAS",
    "BANGLADESH",
    "BARBADOS",
    "BÉLGICA",
    "BOLIVIA",
    "BRASIL",
    "BRUNÉI",
    "BULGARIA",
    "CABO VERDE",
    "CANADÁ",
    "CHILE",
    "CHINA",
    "CHIPRE",
    "COLOMBIA",
    "COLUMBIA BRITÁNICA (CANADÁ)",
    "COREA DEL SUR",
    "COSTA DE MARFIL",
    "COSTA RICA",
    "CROACIA",
    "DINAMARCA",
    "DUBÁI",
    "ECUADOR",
    "EGIPTO",
    "EL SALVADOR",
    "EMIRATOS ÁRABES UNIDOS",
    "ESLOVAQUIA",
    "ESLOVENIA",
    "ESPAÑA",
    "ESTADOS UNIDOS DE NORTEAMÉRICA",
    "ESTONIA",
    "FEDERACIÓN DE BOSNIA Y HERZEGOVINA",
    "FILIPINAS",
    "FINLANDIA",
    "FRANCIA",
    "GEORGIA",
    "GHANA",
    "GIBRALTAR",
    "GRAN DUCADO DE LUXEMBURGO",
    "GRECIA",
    "GUERNSEY",
    "HONG KONG (CHINA)",
    "HUNGRÍA",
    "INDIA",
    "INDONESIA",
    "IRÁN",
    "IRLANDA",
    "ISLA DE MAN",
    "ISLANDIA",
    "ISLAS BERMUDAS",
    "ISLAS CAIMÁN",
    "ISLAS TURCAS Y CAICOS",
    "ISLAS VÍRGENES BRITÁNICAS",
    "ISRAEL",
    "ITALIA",
    "JAMAICA",
    "JAPÓN",
    "JERSEY",
    "JORDANIA",
    "KAZAJISTÁN",
    "KENIA",
    "KUWAIT",
    "LETONIA",
    "LIECHTENSTEIN",
    "LITUANIA",
    "MACAO (CHINA)",
    "MALAUI",
    "MALASIA",
    "MALDIVAS",
    "MALTA",
    "MARRUECOS",
    "MAURICIO",
    "MÉXICO",
    "MONGOLIA",
    "MONTENEGRO",
    "NIGERIA",
    "NICARAGUA",
    "NORUEGA",
    "NUEVA ZELANDA",
    "OMÁN",
    "ONTARIO, CANADÁ",
    "PAÍSES BAJOS",
    "PALESTINA",
    "PANAMÁ",
    "PAPÚA NUEVA GUINEA",
    "PAKISTÁN",
    "PERÚ",
    "POLONIA",
    "PORTUGAL",
    "PRINCIPADO DE MÓNACO",
    "QATAR",
    "QUEBEC, CANADÁ",
    "REINO DE BAHRÉIN",
    "REINO UNIDO",
    "REPÚBLICA CHECA",
    "REPÚBLICA DE CROACIA",
    "REPÚBLICA DE KAZAJISTÁN",
    "REPÚBLICA DE KIRGUISTÁN",
    "REPÚBLICA DE KOREA",
    "REPÚBLICA DE LETONIA",
    "REPÚBLICA DE MACEDONIA DEL NORTE",
    "REPÚBLICA DE SERBIA",
    "REPÚBLICA DE SRPSKA",
    "REPÚBLICA DOMINICANA",
    "REPÚBLICA ESLOVACA",
    "REPÚBLICA SUDAFRICANA",
    "RUMANIA",
    "RUSIA",
    "SINGAPUR",
    "SIRIA",
    "SRI LANKA",
    "SUDÁFRICA",
    "SUECIA",
    "SUIZA",
    "SULTANATO DE OMÁN",
    "TAILANDIA",
    "TAIPÉI CHINA",
    "TANZANIA",
    "TRINIDAD Y TOBAGO",
    "TÚNEZ",
    "TURQUÍA",
    "UCRANIA",
    "UGANDA",
    "UNIÓN MONETARIA DE ÁFRICA OCCIDENTAL (BENIN, BURKINA FASO, GUINEA-BISSAU, COSTA DE MARFIL, MALÍ, NÍGER, SENEGAL Y TOGO)",
    "URUGUAY",
    "UZBEKISTÁN",
    "VIETNAM",
    "ZAMBIA"
];