export const countries = [
    "AFGANISTAN", "ALBANIA", "ALEMANIA", "ANDORRA", "ANGOLA", "ANGUILLA",
    "ANTÁRTIDA", "ANTIGUA Y BARBUDA", "ANTILLAS HOLANDESAS", "ARABIA SAUDITA",
    "ARGELIA", "ARGENTINA", "ARMENIA", "ARUBA", "AUSTRALIA", "AUSTRIA",
    "AZERBAIYAN", "BAHAMAS", "BANGLADESH", "BARBADOS", "BAREIN", "BELGICA",
    "BELICE", "BENIN", "BERMUDA", "BIELORRUSIA", "BIRMANIA", "BOLIVIA",
    "BOSNIA Y HERZEGOVINA", "BOTSUANA", "BRASIL", "BRUNEI", "BULGARIA",
    "BURKINA FASO", "BURUNDI", "BURMA", "BUTAN", "CABO VERDE", "CAMBODIA",
    "CAMERUN", "CANADA", "CATAR", "CHAD", "CHILE", "CHINA", "CHIPRE",
    "CIUDAD DEL VATICANO", "COLOMBIA", "COMORAS", "CONGO", "COREA DEL NORTE",
    "COREA DEL SUR", "COSTA DE MARFIL", "COSTA RICA", "CROACIA", "CUBA",
    "CURAZAO", "DINAMARCA", "YIBUTI-DJIBOUTI", "DOMINICA", "ECUADOR", "EGIPTO",
    "EL SALVADOR", "EMIRATOS ARABES UNIDOS", "ERITREA", "REPUBLICA ESLOVACA",
    "ESLOVENIA", "ESPAÑA", "ESTADOS UNIDOS DE AMERICA", "ESTONIA", "ESUATINI",
    "ETIOPIA", "FILIPINAS", "FINLANDIA", "FIYI", "FRANCIA", "GABON", "GAMBIA",
    "GEORGIA", "GHANA", "GIBRALTAR", "GRANADA", "GRECIA", "GROENLANDIA",
    "GUAM", "GUATEMALA", "GUERNSEY", "GUYANA", "GUINEA", "GUINEA-BISAU",
    "GUINEA ECUATORIAL", "HAITI", "HONDURAS", "HONG KONG", "HUNGRIA",
    "INDIA", "INDONESIA", "IRAK", "IRAN", "IRLANDA", "ISLANDIA", "ISLAS CAIMAN",
    "ISLAS COOK", "ISLAS DE ALAND", "ISLA MAN", "ISLAS MARSHALL",
    "ISLAS PITCAIRN", "ISLAS SALOMON", "ISLAS TURCAS Y CAICOS",
    "ISLAS VIRGENES BRITANICAS", "ISRAEL", "ITALIA", "JAMAICA", "JAPON",
    "ISLA JERSEY", "JORDANIA", "KAZAJISTAN", "KENIA", "KIRGUISTAN", "KIRIBATI",
    "KUWAIT", "KOSOVO", "LAOS (REPÚBLICA DEMOCRÁTICA)", "LATVIA", "LESOTO",
    "LETONIA", "LIBANO", "LIBERIA", "LIBIA", "LIECHTENSTEIN", "LITUANIA",
    "LUXEMBURGO", "MACAU", "MACEDONIA", "MADAGASCAR", "MALASIA", "MALAUI",
    "MALDIVAS", "MALI", "MALTA", "MARRUECOS", "MAURICIO", "MAURITANIA",
    "MEXICO", "MICRONESIA", "MOLDAVIA", "MONACO", "MONGOLIA", "MONTENEGRO",
    "MOZAMBIQUE", "MYAMAR", "NAMIBIA", "NAURU", "NEPAL", "NIUE", "NIEVIS", "NICARAGUA",
    "NIGER", "NIGERIA", "NIEVES", "NORUEGA", "NUEVA ZELANDA", "OMAN", "PAISES BAJOS",
    "PAKISTAN", "PALAOS", "PALESTINA", "PANAMA", "PAPUA NUEVA GUINEA",
    "PARAGUAY", "PERU", "POLINESIA FRANCESA", "POLONIA", "PORTUGAL",
    "PUERTO RICO", "QATAR", "REINO UNIDO", "REPUBLICA CENTROAFRICANA",
    "REPUBLICA CHECA", "REPUBLICA DEL CONGO", "REPUBLICA DEMOCRÁTICA DEL CONGO",
    "REPUBLICA DOMINICANA", "SUDAFRICA", "RUANDA", "RUMANIA", "RUSIA",
    "SAMOA OCCIDENTAL", "SAMOA AMERICANA", "SAN BARTOLOME",
    "SAN CRISTOBAL Y NIEVES", "SAN MARINO", "SAN MARTIN",
    "SAN VICENTE Y LAS GRANADINAS", "SANTA LUCIA", "SANTO TOME Y PRINCIPE",
    "SENEGAL", "SERBIA", "SEYCHELLES", "SIERRA LEONA", "SINGAPUR",
    "SIRIA", "SOMALIA", "SRI LANKA", "SUDAN", "SUDAN DEL SUR",
    "SUECIA", "SUIZA", "SURINAM", "TAILANDIA", "TAIWAN", "TANZANIA",
    "TAYIKISTAN", "TIMOR ORIENTAL", "TOGO", "TONGA", "TRINIDAD Y TOBAGO",
    "TUNEZ", "TURKMENISTAN", "TURQUIA", "TUVALU", "UCRANIA", "UGANDA",
    "URUGUAY", "UZBEKISTAN", "VANUATU", "VENEZUELA", "VIETNAM", "YEMEN",
    "YIBUTI", "ZAMBIA", "ZIMBABWE"
]
