export const reasons = [
    {
        key: 0,
        label: "Posee o controla, directa o indirectamente, el 25% de las acciones, participaciones o derecho a voto de la persona jurídica.",
    },
    {
        key: 1,
        label: "Ejerce control de la personal jurídicas a través de otros medios.",
    },
    {
        key: 2,
        label: "Persona natural que ejerce cargo administrativo superior o ejerce control efectivo, siempre que no se logre identificar por los supuestos anteriores.",
    },
    {
        key: 3,
        label: "Percibe beneficios económicos, directos o indirectos de la fundación de interés privado y ejerce control final eficaz.",
    },
    {
        key: 4,
        label: "Percibe beneficios económicos, directos o indirectos de la fundación de interés privado.",
    },
    {
        key: 5,
        label: "Ejerce control eficaz final de la Fundación de Interés Privado.",
    },
    {
        key: 6,
        label: "Fiduciario de un fideicomiso dentro de la estructura corporativa; o posición similar dentro de otro tipo de estructura jurídica.",
    },
    {
        key: 7,
        label: "Fideicomitente de un fideicomiso dentro de la estructura corporativa; o posición similar dentro de otro tipo de estructura jurídica.",
    },
    {
        key: 8,
        label: "Protector de un fideicomiso dentro de la estructura corporativa; o posición similar dentro de otro tipo de estructura jurídica.",
    },
    {
        key: 9,
        label: "Beneficiario de un fideicomiso dentro de la estructura corporativa; o posición similar dentro de otro tipo de estructura jurídica.",
    },
    {
        key: 10,
        label: "Persona que ejerce control eficaz final de un fideicomiso dentro de la estructura corporativa; o posición similar dentro de otro tipo de estructura jurídica.",
    },
    {
        key: 11,
        label: "Liquidador o curador de proceso de quiebra.",
    },
    {
        key: 12,
        label: "Persona natural que actúe como albacea o representante personal del patrimonio de un socio o accionista fallecido.",
    },
]